import React, { Children } from "react"
import classNames from "classnames"

export const Box = ({ children, blue, white, noshadow, centered, noBg }) => (
  <div
    className={classNames("box has-family-pt-sans", {
      "is-shadowless": noshadow,
      "has-text-centered": centered,
      "has-no-background": noBg,
    })}
  >
    {children}
  </div>
)
