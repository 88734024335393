import React from "react"
import classNames from "classnames"

export const CardImage = ({ children }) => (
  <div className="card-image">{children}</div>
)

export const CardContent = ({ children }) => (
  <div className="card-content">{children}</div>
)

export const CardContentTitle = ({ children }) => (
  <h2 className="has-text-weight-bold">{children}</h2>
)

export const CardContentSubtitle = ({ children, bottom }) => (
  <p
    className={classNames("is-size-6", {
      "mb-1": bottom,
    })}
  >
    {children}
  </p>
)

export const CardContentPrice = ({ children }) => (
  <span class="tag is-success is-light mr-auto">{children}</span>
)

export const CardContentTags = ({ tags }) => {
  return (
    <div class="tags">
      {tags.map((item, index) => (
        <span key={index} class="tag is-light">
          {item}
        </span>
      ))}
    </div>
  )
}

export const Card = ({ children, isShadowless, transparent }) => (
  <div
    className={classNames("card has-text-centered hvr-underline-from-center", {
      "is-shadowless": isShadowless,
      "has-background-transparent": transparent,
    })}
  >
    {children}
  </div>
)
